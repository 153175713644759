/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef } from "react";
import { Grid, Stack, TypographyProps } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { SectionHeading } from "../containers";
import { useHasBeenAlmostVisible } from "../hooks";
import { Media as MediaComponent, PpMedia } from "../media";
import {
  BackgroundImage,
  Brand,
  BrandsContainer,
  ContentContainer,
  SectionContainer,
} from "./styles";

type PpMediaPromotion = {
  backgroundImage?: ImageDataLike | null;
  brands: string[];
  description: string;
  media?: PpMedia["media"];
  sectionName: string;
  textColor?: TypographyProps["color"];
  title: string;
};

export function MediaPromotion({
  backgroundImage,
  brands,
  description,
  media,
  sectionName,
  textColor = "primary.contrastText",
  title,
  ...rest
}: PpMediaPromotion): React.ReactElement | null {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  const tempBrands = ["nbc", "abc", "pbs", "cbs", "sky", "rai"];

  return (
    <SectionContainer {...rest}>
      {backgroundImage && (
        <BackgroundImage
          alt={`${title}-image`}
          image={getImage(backgroundImage)}
        />
      )}
      <ContentContainer>
        <Grid ref={elementRef} container spacing={{ xs: 4, lg: 12 }}>
          {media && (
            <Grid item lg={6} sx={{ display: { lg: "block", xs: "none" } }}>
              <MediaComponent media={media} />
            </Grid>
          )}
          <Grid item lg={media && media.length ? 6 : 12} xs={12}>
            <Stack alignItems="center">
              <SectionHeading
                description={description}
                sectionName={sectionName}
                textAlign="center"
                textColor={textColor}
                title={title}
              />
              <BrandsContainer container spacing={{ xs: 6, md: 10 }}>
                {/* {brands.map(
                  (logo): React.ReactElement => (
                    <Brand key={logo} item xs={4}>
                      {shouldRenderContent ? <img alt="" src={logo} /> : <div />}
                    </Brand>
                  )
                )} */}
                {tempBrands.map(
                  (logo): React.ReactElement => (
                    <Brand key={logo} item xs={4}>
                      {shouldRenderContent ? (
                        <span className={`icon-${logo}`} />
                      ) : (
                        <div />
                      )}
                    </Brand>
                  )
                )}
              </BrandsContainer>
            </Stack>
          </Grid>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
}
