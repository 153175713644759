import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "../containers";

export const SectionContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    ${theme.cssMixins.mediaPromotionBackground};
  `}
`;

export const BackgroundImage = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    position: absolute !important;
    ${theme.cssMixins.stretchAll};
    &:after {
      content: "";
      position: absolute;
      ${theme.cssMixins.stretchAll};
      ${theme.cssMixins.heroImgLinearGradient};
    }
  `}
`;

export const ContentContainer = styled(Container)`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    z-index: 100;
    padding-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(6)};
    ${theme.breakpoints.up("md")} {
      padding-top: ${theme.spacing(10)};
      padding-bottom: ${theme.spacing(10)};
    }
  `}
`;

export const BrandsContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: 0 !important;
    ${theme.breakpoints.between("sm", "lg")} {
      max-width: 60%;
    }
  `}
`;

export const Brand = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowCentered};
    > div {
      height: 26px;
    }
    > span:before {
      font-size: 26px;
      color: ${theme.palette.primary.light};
    }
    ${theme.breakpoints.up("md")} {
      > div {
        height: 48px;
      }
      > span:before {
        font-size: 48px;
      }
    }
  `};
`;
