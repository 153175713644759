import React from "react";
import { Introduction as IntroductionComponent } from "@italymondo/core-ui";
import { formatData } from "./config";
import { useStaticIntroductionQuery } from "./query";

export function Introduction(): React.ReactElement | null {
  const data = useStaticIntroductionQuery();

  if (!data.site.aboutPage[0]) {
    return null;
  }

  const introductionData = formatData(data);

  return <IntroductionComponent {...introductionData} />;
}
