import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import {
  ContactForm,
  Differences,
  Founder,
  Hero,
  Introduction,
  Media,
  Metrics,
  Offices,
  Subbrands,
  Team,
} from "../../sections/About";
import { AboutQuery } from "./index.generated";

type PpAboutPage = {
  data: AboutQuery;
  location?: {
    pathname?: string;
  };
};

export default function AboutPage({
  data: {
    cms: { site },
  },
  location,
}: PpAboutPage): React.ReactElement {
  const alertBarData = site.aboutPage[0].alertBar?.message
    ? {
        link: {
          text: site.aboutPage[0].alertBar?.anchorText || "",
          url: getPathname(site.aboutPage[0].alertBar?.page.url) || "",
        },
        text: site.aboutPage[0].alertBar?.message || "",
      }
    : null;

  return (
    <Layout
      alertBar={alertBarData}
      showProgressBar={site?.aboutPage[0].progressBar}
    >
      <SEO
        description={site.aboutPage[0]?.listingSummary || ""}
        image={site.aboutPage[0]?.listingImage?.url}
        location={location}
        title={site.aboutPage[0]?.listingTitle || ""}
      />
      <Hero />
      <Introduction />
      <Founder />
      <Metrics />
      <Differences />
      <Subbrands />
      <Offices />
      <Media />
      {/* <Team /> */}
      <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query About {
    cms {
      site(hostname: "www.italymondo.com") {
        aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
          ... on CMS_IMAboutPage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            heroImage {
              url
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
