import { graphql, useStaticQuery } from "gatsby";
import { AboutMetricsQuery } from "./query.generated";

export type CMSMetricsData = AboutMetricsQuery["cms"]["site"];

export function useStaticMetricsQuery(): CMSMetricsData {
  const data = useStaticQuery<AboutMetricsQuery>(
    graphql`
      query AboutMetrics {
        cms {
          site(hostname: "www.italymondo.com") {
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                metrics {
                  metric {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
