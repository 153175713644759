import { graphql, useStaticQuery } from "gatsby";
import { AboutOfficesQuery } from "./query.generated";

export type CMSOfficesData = AboutOfficesQuery["cms"];

export function useStaticOfficesQuery(): CMSOfficesData {
  const data = useStaticQuery<AboutOfficesQuery>(
    graphql`
      query AboutOffices {
        cms {
          site(hostname: "www.italymondo.com") {
            companydetail {
              office1Country
              office1FullAddress
              office2Country
              office2FullAddress
            }
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                officesDescription
                officesMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                officesMediaPlacement
                officesSectionName
                officesTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
