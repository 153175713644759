import React from "react";
import {
  Button,
  Founder as FounderComponent,
  getPathname,
  Link,
} from "@italymondo/core-ui";
import { useStaticFounderQuery } from "./query";

export function Founder(): React.ReactElement | null {
  const data = useStaticFounderQuery();

  if (!data?.aboutPage[0]) {
    return null;
  }

  function founderButton(url: string): React.ReactElement {
    return (
      <Link to={url}>
        <Button color="primary" variant="contained">
          {data?.aboutPage[0]?.founderCta?.title}
        </Button>
      </Link>
    );
  }

  return (
    <FounderComponent
      actions={founderButton(
        getPathname(data.aboutPage[0]?.founderCta?.page?.url)
      )}
      description={data.aboutPage[0].founderDescription || ""}
      media={data.aboutPage[0].founderMedia[0]}
      mediaPlacement={data.aboutPage[0].founderMediaPlacement}
      sectionName={data.aboutPage[0].founderSectionName || ""}
      title={data.aboutPage[0].founderTitle || ""}
      titleBackground="signature"
    />
  );
}
