import { graphql, useStaticQuery } from "gatsby";
import { AboutMediaQuery } from "./query.generated";

export type CMSMediaData = AboutMediaQuery["cms"]["site"];

export function useStaticMediaQuery(): CMSMediaData {
  const data = useStaticQuery<AboutMediaQuery>(
    graphql`
      query AboutMedia {
        cms {
          site(hostname: "www.italymondo.com") {
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                mediaBrands {
                  brand {
                    logo {
                      url
                    }
                  }
                }
                mediaDescription
                mediaMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                mediaMediaPlacement
                mediaSectionName
                mediaTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
