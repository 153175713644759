import React from "react";
import { MediaPromotion } from "@italymondo/core-ui";
import { useStaticMediaQuery } from "./query";

export function Media(): React.ReactElement | null {
  const data = useStaticMediaQuery();

  if (!data?.aboutPage[0]) {
    return null;
  }

  return (
    <MediaPromotion
      brands={data.aboutPage[0].mediaBrands.map(({ brand }) => brand.logo.url)}
      description={data.aboutPage[0].mediaDescription}
      media={data.aboutPage[0].mediaMedia[0]}
      sectionName={data.aboutPage[0].mediaSectionName || ""}
      title={data.aboutPage[0].mediaTitle}
    />
  );
}
