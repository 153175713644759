import { graphql, useStaticQuery } from "gatsby";
import { AboutContactFormQuery } from "./query.generated";

export type CMSContactFormData = AboutContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<AboutContactFormQuery>(
    graphql`
      query AboutContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                contactFormSectionName
                footerContactForm {
                  confirmationPage {
                    url
                  }
                  description
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
