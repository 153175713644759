/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import {
  Media,
  MediaPlacementType,
  RichText,
  SectionContainer,
} from "@italymondo/core-ui";
import { Grid, GridDirection, Typography } from "@mui/material";
import { useStaticOfficesQuery } from "./query";
import { Heading } from "./styles";

export function Offices(): React.ReactElement | null {
  const data = useStaticOfficesQuery();

  if (!data.site?.aboutPage[0] || !data.site.companydetail) {
    return null;
  }

  const { officesDescription, officesSectionName, officesTitle } =
    data.site.aboutPage[0];
  const {
    office1Country,
    office1FullAddress,
    office2Country,
    office2FullAddress,
  } = data.site.companydetail;

  function renderLocationAddress(
    country: string,
    fullAddress: string
  ): React.ReactElement {
    return (
      <Grid
        container
        item
        alignItems={{ md: "flex-start", xs: "center" }}
        direction="column"
        sm={5}
        xs={6}
      >
        <Typography
          gutterBottom
          color="primary.light"
          variant="locationHeading"
        >
          {country}
        </Typography>
        <RichText color="primary" text={fullAddress} variant="body1" />
      </Grid>
    );
  }

  const direction = {
    xs: "column-reverse" as GridDirection,
    md: "row" as GridDirection,
  };

  switch (data.site.aboutPage[0].mediaPlacement) {
    case MediaPlacementType.RIGHT:
      direction.md = "row-reverse";
      break;
    case MediaPlacementType.TOP:
      direction.xs = "column";
      break;
    default:
      break;
  }

  return (
    <SectionContainer>
      <Grid
        container
        alignItems="center"
        direction={direction}
        spacing={{ xs: 6, md: 12 }}
      >
        <Grid item zeroMinWidth sm={6} style={{ maxWidth: "100%" }}>
          <Media media={data.site.aboutPage[0].officesMedia[0]} />
        </Grid>
        <Grid container item direction="column" sm={6} spacing={4}>
          <Grid item>
            <Heading
              alignItems={{ xs: "center", md: "flex-start" }}
              description={officesDescription || ""}
              sectionName={officesSectionName || ""}
              standardWidth={false}
              title={officesTitle || ""}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="space-between"
            spacing={{ xs: 1, md: 0 }}
          >
            {renderLocationAddress(office1Country, office1FullAddress)}
            {renderLocationAddress(office2Country, office2FullAddress)}
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}
