import { graphql, useStaticQuery } from "gatsby";
import { AboutDifferencesQuery } from "./query.generated";

export type CMSDifferencesData = AboutDifferencesQuery["cms"]["site"];

export function useStaticDifferencesQuery(): CMSDifferencesData {
  const data = useStaticQuery<AboutDifferencesQuery>(
    graphql`
      query AboutDifferences {
        cms {
          site(hostname: "www.italymondo.com") {
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                differences {
                  difference {
                    description
                    icon {
                      url
                    }
                    title
                  }
                }
                differenceDescription
                differenceSectionName
                differenceTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
