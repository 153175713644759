import { PpIntroduction } from "@italymondo/core-ui";
import { CMSIntroductionData } from "./query";

export function formatData(data: CMSIntroductionData): PpIntroduction {
  if (!data.site) {
    throw new Error("Expected a site object");
  }

  return {
    media: data?.site?.aboutPage[0].introMedia[0],
    mediaPlacement: data?.site?.aboutPage[0].introMediaPlacement,
    paragraph: data?.site?.aboutPage[0].introParagraph || "",
    shadowHeader: data?.site?.aboutPage[0].introShadowHeader || "",
  };
}
