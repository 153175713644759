import { graphql, useStaticQuery } from "gatsby";
import { AboutSubbrandsQuery } from "./query.generated";

export type CMSSubbrandsData = AboutSubbrandsQuery["cms"]["site"];

export function useStaticSubbrandsQuery(): CMSSubbrandsData {
  const data = useStaticQuery<AboutSubbrandsQuery>(
    graphql`
      query AboutSubbrands {
        cms {
          site(hostname: "www.italymondo.com") {
            aboutPage: pages(contentType: "italymondo_about.IMAboutPage") {
              ... on CMS_IMAboutPage {
                subBrands {
                  brand {
                    logo {
                      title
                      url
                    }
                    page {
                      url
                    }
                  }
                }
                subBrandsDescription
                subBrandsSectionName
                subBrandsTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
